.map-tittle{
  font-size: 50px;
  font-weight:900;
  margin: 0;
}

@media (max-width: 825px) {
  .map-tittle {
    font-size: 43px;
    font-weight:900;
  }
}
@media (max-width: 725px) {
  .map-tittle {
    font-size: 40px;
    font-weight:900;
  }
}

@media (max-width: 625px) {
  .map-tittle {
    font-size: 30px;
    font-weight:900;
  }
}

@media (max-width: 465px) {
  .map-tittle {
    font-size: 25px;
    font-weight:900;
  }
}
  