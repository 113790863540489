/* ------------------------- HEADER ------------------------- */
.top {
  background: rgb(34, 34, 34);
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0px;
  z-index: 2;
  height: 60px;
  padding-top: 30px;
}
.header-black{
  margin-top: -30px;
  margin-left: 0px;
}
.header-left{
  float: left;
  margin-left: 20px;
  color: white;
}
.white{
 font-size: 16px;
}
.header-separador{
  border-right: 1px solid #fff;
    float: left;
    list-style: none outside none;
    margin-left: 25px;
    width: auto;
}
.header-idioma{
  color: #fff;
  padding-left: 4px;
  margin-left: 20px;
}
.header-contacto{
  float: right;
  width: 60%;
  text-align: left;
}
.nav-item{
  color:white;
  width: auto;
  float: left;
  list-style: none;
  min-width: 32px;
}
.ms-auto{
margin: auto !important;
}
.header_ {
    position: absolute; /*fixed will make this follow down when scrolling down*/
    top: 60px;
    background-color: white;
    text-align: center;
    z-index: 2;
    width: 100%;
  }
  .navbar-open {
    background-color: black !important; 
  }
  .navbar-content-open{
    color: white !important;
     
  }
  
  .hide-language-icon{
    display: none;
  }
  
  .header-logo{
    width: 10vw;
    margin-left: 20px;
  }
  
  .header-text{
    color:black;
    font-weight: 700;
  }
  
  .header-text-open{
    color: white !important;
  }
  
  .header-text-open:hover{
    background-color: white;
    color:black !important;
    border-radius: 20px;
  }
  .navbar-toggler-open{
  background-color: rgba(0, 0, 0);
  /* display: none; */
  }
  .close-cross{
    color: white !important;
  }

  .submenu {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 160px;
    z-index: 1;
    margin:0;
    padding:0;
    margin-left:10px;
}

.submenu li {
    display: block;
    text-align: left;
    padding: 12px;
    padding-left: 20px;
    border-bottom: 1px solid white;
    font-weight: bold;
}

.submenu li a {
    padding: 0px;
    text-decoration: none;
    color:white;
    padding: 10px;
}

.submenu li a:hover {
    background-color: #ccc;
}

/* Mostrar el submenú al pasar el ratón */
.navbar ul li:hover .submenu {
    display: block;
}
  
  /* Default styles for the logo */
  .logo {
    display: none; /* Hide the logo by default */
  }
  @media (max-width: 991px) {
    .logo-mobile {
      display: block; /* Show the mobile logo */
    }
  }
  
  /* Media query for screens larger than 991px */
  @media (min-width: 992px) {
    .logo-desktop {
      display: block; /* Show the desktop logo */
    }
  }
  
  
  /* extra small devices (vertical phones) 300 px and up*/
  @media (max-width: 576px) { 
    .header-logo{
      width: 20vw;
      display: flex;
      justify-content: center;
      
    }
  
    .header_ {
      position: absolute; /*fixed will make this follow down when scrolling down*/
      top: 0px;
      left: 10vw;
      right: 10vw;
      background-color: rgba(0, 0, 0, 0) ; /*here the logo is transparent*/
      border-radius: 40px;
      text-align: center;
      z-index: 2;
    }
    .navbar.open {
      background-color: white !important; /* change the background color when the toggler button is open */
    }
  }
  
  
  
  
  /* ------------------------- HEADER END --------------------- */