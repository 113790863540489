body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.documents-li li::before {
  content: url("static/images/check-solid.svg");

  width: 20px;
  height: 20px;
  display: inline-block;
  padding-right: 5px;
}

.documents-li {
  list-style: none;
}


/* .documents-li li { 
  list-style-image: url("");
}  */

/* 
  width: 20px;
  height: 20px; */
  /* background-size: 50px 50px; */
  
  /* background-size: contain; */

 /* li p {
  font-size: 1.2rem;
 } */