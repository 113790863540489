@import url('./styles/BestChoiceComponent.css');
@import url('./styles/CarDetailPageComponent.css');
@import url('./styles/CompleteFleetComponent.css');
@import url('./styles/FAQComponent.css');
@import url('./styles/FooterComponent.css');
@import url('./styles/HeaderComponent.css');
@import url('./styles/MapComponent.css');
@import url('./styles/TermsConditionsComponent.css');
@import url('./styles/WhyRentComponent.css');

/* ------------------------- FORM --------------------------- */
.form-tittle{
  color: black;
  font-family: 'Poppins',sans-serif;
  font-weight: 900;
  font-size: 30px;
  
}
@media (max-width:672px) {
  .form-tittle{
    font-size: 24px;
  }
}

@media (max-width:543px) {
  .form-tittle{
    font-size: 20px;
  }
}

@media (max-width:465px) {
  .form-tittle{
    font-size: 19px;
  }
}
.form-container{
  max-width: 50vw;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 30px;
}

.form-container-mobile{
  max-width: 90vw;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin-top: 2.5vh;
}

@media screen and (min-width: 767px) and (max-width: 1031px) {
  .hide-on-tablet{
    display: none;
  }
}

.red-color{
  color: red;
}

/* ------------------------- FORM --------------------------- */



/* ----------------- complete fleet -------------------------- */
.complete-fleet-img{
  width: 70%;
  align-items: center;
}

@media (max-width: 576px) { 
  .complete-fleet-img{
    width: 100%;
    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-left: 3vw;
    margin-right: 3vw;
  }
}

/* ----------------- complete fleet END--------------------- */
/* --------------- CAR CONTAINER ----------------------------- */
.car-container{
  background-image:  url('static/images/emifon.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
  background-color: #000000;
}

.car-card{
  border-radius: 30px;
}

.line-separator {
  border-top: 3px solid black;
}



.logo-img{

  object-fit: contain;
  max-width: 50%;
  max-height: 50%;
    
}


.text-model-container{
  position: relative;
}

/* .text-model-container:after {
  content: "";
  display: block;
  width: 4vw;
  height: 5px;
  background: #000;
  right: 0;
  top: 50%;
  position: absolute;
} */

.text-model{
  font-size: 1.3rem;
  font-weight: 900;
}

.car-image{
  object-fit: contain;
  max-width: 70%;
}
.car-image:hover{
  transform: scale(1.2);
  transition: all .6s;
}
.car-charact-img{
  object-fit: contain;
  max-width: 90%;
}
.rounded-right-corners {
  border-top-left-radius: 0;
  border-radius: 0px 30px 30px 30px;
}
@media (max-width: 767px) {
  .text-model-container:after {
    content: "";
    display: block;
    width: 0vw;
  }
  .car-charact-img{
    object-fit: contain;
    max-width: 100%;
  }
  .logo-img{

    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
  .text-model{
    font-size: 1rem;
  }
}
.bg-gold{
  background-color: #c8c624 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price-overflow{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-price b {
  white-space: nowrap; /* prevent the price from wrapping */
}

@media screen and (min-width: 768px) and (max-width: 1062px) {
  .text-price b:before {
    content: '\A'; /* add a line break before the price */
    white-space: pre; /* preserve the line break */
  }
}

/* --------------- CAR CONTAINER END ------------------------ */

/* ----------- HOW WORKS CONTAINER -------------------------- */
.container-how-works {
  opacity: 0;
  transition: opacity 1s;
  width: 50%;
  /* flex-shrink: 0; */
}

.steps-tittle-desktop{
  font-family: "Poppins", Sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
}

.how-works-tittle{
  font-weight: 900;
  font-size: 38px;
}
.steps-mobile-tittle{
  font-size: 0.9rem;
  font-weight: 900;
}

.steps-mobile-text{
  font-size: 0.8rem;
  font-weight: 600;
}
.steps-path{
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.fade-in {
  opacity: 1;
}

.row-container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) { 
  .container-how-works {
    width: 100%;
  }
}
/* ----------- HOW WORKS CONTAINER -------------------------- */

/* ----------- ANGLE DOWN AFTER HOW WORKS --------------------*/
.angle-down{
  width: 4rem;
}

.angle-down-parent{
  position: relative;
}
.angle-down-parent:before {
  content: "";
  display: block;
  width: 40vw;
  height: 5px;
  background: #000;
  left: 0;
  top: 50%;
  position: absolute;
}
.angle-down-parent:after {
  content: "";
  display: block;
  width: 40vw;
  height: 5px;
  background: #000;
  right: 0;
  top: 50%;
  position: absolute;
}

/* ----------- ANGLE DOWN AFTER HOW WORKS END  ---------------*/

/* --------------------- REQUIRED DOCUMENTS ----------------- */
.required-documents{
  background-image:  url('static/images/emini_fondo_documents.webp');
  background-repeat: no-repeat;
  background-size: contain;
  
  background-position: right !important;
  background-color: #000000;
  border-radius: 90px 0px 90px 90px;
}

.required-documents-title{
  font-size: 1.9rem;
  font-weight: 900;
  background-color: white;
  border-radius: 90px 90px 90px 90px;
  width: 50%;
  text-align: center;
}

.required-documents-text{
  font-size: 1.9rem;
  font-weight: 900;
  color:white;
  border-radius: 90px 90px 90px 90px;
  width: 50%;
  text-align: center;
}

.required-documents-list-tittle{
  font-weight: 900;
}

/* .doc-req-img{
  width: 100%;
  height: auto;

} */
@media (max-width: 576px) { 
  .required-documents{

    background-size: auto 3vh;    
    background-position: right !important;
    background-color: #000000;
  }
  
/* .doc-req-img{
  width: 100%;
  height: auto;

} */
}
@media (max-width: 768px) { 
  .required-documents{

    background-size: auto 5vh;    
    background-position: right !important;
    background-color: #000000;
    
  }
  
  .required-documents-title{
    font-size: 1.3rem;
    font-weight: 900;
    background-color: white;
    width: 70%;
    text-align: center;
  }
  
  .required-documents-text{
    font-size: 1.3rem;
    font-weight: 900;
    color:white;
    border-radius: 90px 90px 90px 90px;
    width: 50%;
    text-align: center;
  }
  
  .required-documents-list-tittle{
    font-weight: 900;
  }
}

/* --------------------- REQUIRED DOCUMENTS END -------------- */


/* ---------------------- VALUABLE INFO --------------------- */
.valuable-information-tittle-container{
  background-image:  url('static/images/adam-le-sommer.webp');
  min-height: 20vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center !important;
}



.valuable-information-tittle{
  font-size: 1.3rem;
  font-weight: 1000;
  background-color: white;
  width: 90%;
  text-align: center;
  border-radius: 30px;
}

.valuable-information-text{
  font-size: 1.3rem;
  font-weight: 1000;
  color:white;
  border-radius: 90px 90px 90px 90px;
  width: 50%;
  text-align: center;
}

.valuable-information{
  background-image:  url('static/images/valuable-information-desktop.webp');
  max-height: 100vh;
  padding-bottom: 10vh;
  
  /* width: 100%; */

  background-repeat: no-repeat;
  /* background-size: 100vw 100vh; */
  /* background-position: center !important; */
  background-position: top center;
  background-size: 100% auto;
}

.valuable-information-es{
  background-image:  url('static/images/vi_bg_ES.webp');
  max-height: 100vh;
  padding-bottom: 10vh;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
}


.row-modal-information{
  margin-left: 25vw !important;
  
}

.modal-image-trigger{
  margin-top: 8rem;
  width: 10vw;
  height: 10vw;
}
/* // Large devices (desktops, 992px and up) */
@media (max-width: 992px) { 
  .modal-image-content{
    max-width: 95vw ;
  }
}

@media (max-width: 576) {
  .valuable-information{
    background-image:  url('static/images/valuable-information-mobile.webp');
    max-height: 100vh;
    padding-bottom: 5vh;
    /* width: 100%; */
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
  }
  .valuable-information-es{
    background-image:  url('static/images/valuable-information-mobile.webp');
    max-height: 100vh;
    padding-bottom: 5vh;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
  }
  .modal-image-trigger{
    width: 38vw;
    height: 38vw;
    margin-top: 4rem;
  }
  .row-modal-information{
    margin-left: 0vw !important;
  }
  .modal-image-content{
    max-width: 100vw ;
  }
}

@media (max-width: 767px) {
  .valuable-information{
    background-image:  url('static/images/valuable-information-mobile.webp');
    max-height: 100vh;
    /* width: 100%; */
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
  }
  .valuable-information-es{
    background-image:  url('static/images/valuable-information-mobile.webp');
    max-height: 100vh;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
  }
  .modal-image-trigger{
    width: 21vw;
    height: 21vw;
    margin-top: 3.5rem;
  }
  .row-modal-information{
    margin-left: 0vw !important;
  }
  .modal-image-content{
    max-width: 96vw ;
  }
}
/* --------------------- VALUABLE INFO END ------------------- */


/* extra small devices (vertical phones) 300 px and up*/
@media (min-width: 300px) { 
 
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {


 }



 @media (min-width: 767px) {
  .hide-on-desktop {
      display: none ;
  }
}

@media (max-width: 767px) {
  .hide-on-mobile {
      display: none;
  }
}
@media screen and (min-width: 767px) and (max-width: 1031px) {
  .hide-on-tablet{
    display: none;
  }
}

@media (max-width: 1031px) {
  .show-on-tablet-mobile-flex{
    display: flex !important;
  }
}
@media (max-width: 1245px) {
  .show-on-tablet-mobile-flex-form{
    display: flex !important;
  }
@media screen and (min-width: 767px) and (max-width: 1246px){
  .why-rent-show-tablet{
    display: flex !important;
  }
}
}
@media (min-width: 767px) and (max-width: 1246px) {
  .hide-on-desktop {
    display: none;
  }
  .hide-on-mobile {
    display: none;
  }
  .show-767-1246 {
    display: block;
  }
  .font-tablet{
    font-size: 0.9rem !important; 
  }
}

.carousel-caption{
  right: 2.5vw;
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.pt-4 {
  padding-top: 2.6rem !important;
}
.white{
  color: #fff;
}
/* d-none d-md-block just shows desktop */



/* ------about us------- */

.about-us-container{
  background-image:  url('static/images/fonde.webp'); 
  background-size: contain;  
  background-repeat: no-repeat;
}

.about-us-tittle{
  
  text-align: center;
  font-family: "Poppins", Sans-serif;
  font-size: 2.2rem;
  font-weight: 800;
  line-height: 1px;
  color: #474747;
  margin-top: 20vh;
  margin-bottom: 10vh;   
}
.about-us-text{
  text-align: justify;
  font-family: "Poppins", Sans-serif;
  font-size: 1.1rem;
}



/* Whatsapp button */

.floating {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: white;
  color: #fff;
  border-radius: 50%;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.float-button{
	margin-top:16px;
}
.notification {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Whatsapp button */


/* BLACK AND WHITE BACKGROUNDS FOR THE TITTLES OF THE WEBSITE */
.white-black {
  background-color: white;
  color: black;

}

.black-white {
  background-color: black;
  color: white;
  
}
.white-black,
.black-white {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.separador{
  width: 100%;
}
.promociones{
  margin: auto;
  width: 90%;
}
.promociones h1{
  margin-left: 20px;
}
.home-ofertas{
  float: left;
  width: 32%;
  margin: 8px;
}
.carouselHome img{
  width: 200px !important;
}

.card-custom {
  margin-left: 4%;
  margin-top:  20px;
}
.card-custom img {
  width:80% !important ;
  height: 80%;
  margin: auto;
}
.card-custom .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.card-custom .card-footer {
  display: flex;
  justify-content: space-around;
}

.title-location{
  padding-top:40px;
}
.card-custom-title-price{
  color:#bfbfbf;
  font-weight: 300 !important;
  font-size: 16px;
}
.card-custom del{
  color: red;
}
.card-custom-title-green{
  color:#00bf00;
  font-weight: 300 !important;
  font-size: 16px;

}
.red{
  color: red;
}
.w900{
  font-weight: 700;
}
.bg-content-red{
  height: 350px;
}
.bg-red{
background-color: #f11302;
height: 150px
}
.bg-black-franja{
background-color: #000;
height: 150px
}
.bg-white-title{
  color: white;
  margin-top: 40px;
  text-align: center;
  font-weight: 700;
}
.card-body-custom{
  min-height: 150px;
  margin: 20px;
}
.small{
  font-size: 16px;
  color: red;
  position: absolute;
}

.about-us-container{
  margin-top:140px
}
.text-align-left{
text-align: left;
}
.col-33{
  width: 33%;
}
.card a{
  text-decoration: none;
}
.card-img-top{
    width: 240px !important;
    height: 200px !important;
    margin: auto;
    display: block;
}
.item-img-carrousel{
  color: black;
  font-size: 24px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 800;

}

.us-tittle{
  text-align: left;
  font-family: "Poppins", Sans-serif;
  font-size: 2.2rem;
  font-weight: 800;
  line-height: 1px;
  color: #474747;
  margin-top: 10vh;
}

.text-center{
  color: #474747;
}

.bg-content-red p {
  font-size: 12px;
}
#booking-form{
  float: right;
  margin-right: 20px;
}
#submitBtn{
  background-color: black;
}