.complete-fleet-img-container {
    flex: 0 0 auto;
    width: 139px; /* Set the desired width */
    height: auto;
  }

.complete-fleet-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

.complete-fleet-tittle-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px; /* Adjust the spacing between image and text */
    height: 100%;
}
.complete-fleet-tittle{
    font-size: 40px;
    font-weight:900;
    margin: 0;
  }
  
  @media (max-width: 847px) {
    .complete-fleet-tittle {
      font-size: 33px;
      font-weight:900;
    }
  }  

  @media (max-width: 825px) {
    .complete-fleet-tittle {
      font-size: 32.5px;
      font-weight:900;
    }
  }
  @media (max-width: 725px) {
    .complete-fleet-tittle {
      font-size: 28px;
      font-weight:900;
    }
  }
  
  @media (max-width: 625px) {
    .complete-fleet-tittle {
      font-size: 24px;
      font-weight:900;
    }
    .complete-fleet-img {
        width: auto;
        height: auto;
        max-width: 70%;
        max-height: 70%;
      }
  }
  
  @media (max-width: 465px) {
    .complete-fleet-tittle {
      font-size: 24px;
      font-weight:900;
    }
    .complete-fleet-img {
        width: auto;
        height: auto;
        max-width: 70%;
        max-height: 70%;
      }

    .complete-fleet-tittle-container{

        margin-left: 0px; /* Adjust the spacing between image and text */

    }
  }
/* Spanish version */

.flota-completa-img-container {
    flex: 0 0 auto;
    width: 139px; /* Set the desired width */
    height: auto;
  }

.flota-completa-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

.flota-completa-tittle-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px; /* Adjust the spacing between image and text */
    height: 100%;
}
.flota-completa-tittle{
    font-size: 40px;
    font-weight:900;
    margin: 0;
  }
  
  @media (max-width: 847px) {
    .flota-completa-tittle {
      font-size: 33px;
      font-weight:900;
    }
  }  

  @media (max-width: 825px) {
    .flota-completa-tittle {
      font-size: 32.5px;
      font-weight:900;
    }
  }
  @media (max-width: 725px) {
    .flota-completa-tittle {
      font-size: 28px;
      font-weight:900;
    }
  }
  
  @media (max-width: 625px) {
    .flota-completa-tittle {
      font-size: 25px;
      font-weight:900;
    }
    .flota-completa-img {
        width: auto;
        height: auto;
        max-width: 70%;
        max-height: 70%;
      }
  }
  
  @media (max-width: 465px) {
    .flota-completa-tittle {
      font-size: 24px;
      font-weight:900;
    }
    .flota-completa-img {
        width: auto;
        height: auto;
        max-width: 70%;
        max-height: 70%;
      }

    .flota-completa-tittle-container{

        margin-left: 0px; /* Adjust the spacing between image and text */

    }
  }



  /* the carousel */


  @media (min-width: 767px) {
    .hide-carousel-on-desktop {
        display: none ;
    }
  }
  
  @media (max-width: 767px) {
    .hide-fleet-on-mobile {
        display: none;
    }
  }