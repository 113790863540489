.best-choice-tittle{
    font-size: 40px;
    font-weight:900;
    margin: 0;
  }
  
  @media (max-width: 825px) {
    .best-choice-tittle {
      font-size: 33px;
      font-weight:900;
    }
  }
  @media (max-width: 725px) {
    .best-choice-tittle {
      font-size: 30px;
      font-weight:900;
    }
  }
  
  @media (max-width: 625px) {
    .best-choice-tittle {
      font-size: 22px;
      font-weight:900;
    }
  }
  
  @media (max-width: 465px) {
    .best-choice-tittle {
      font-size: 18px;
      font-weight:900;
    }
  }

/* spanish version */
.mejor-opcion-tittle{
  font-size: 38px;
  font-weight:900;
  margin: 0;
}

@media (max-width: 832px) {
  .mejor-opcion-tittle {
    font-size: 33px;
    font-weight:900;
  }
}
@media (max-width: 725px) {
  .mejor-opcion-tittle {
    font-size: 30px;
    font-weight:900;
  }
}

@media (max-width: 663px) {
  .mejor-opcion-tittle {
    font-size: 26px;
    font-weight:900;
  }
}

@media (max-width: 576px) {
  .mejor-opcion-tittle {
    font-size: 24px;
    font-weight:900;
  }
}

@media (max-width: 534px) {
  .mejor-opcion-tittle {
    font-size: 22px;
    font-weight:900;
  }
}

@media (max-width: 491px) {
  .mejor-opcion-tittle {
    font-size: 18px;
    font-weight:900;
  }
}
/* --------------------- BEST CHOICE ------------------------- */
/* ---------------------------------------- old, need review   */

.best-choice-container{
  background-image:  url('../static/images/adam-le-sommer.webp');
  min-height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
}
.best-choice-header{
  width: 75%;
  height: 50%;
}

@media (max-width: 576px) { 
  .best-choice-header{
    width: 100%;
  }
}
/* --------------------- BEST CHOICE END --------------------- */