.why-rent-tittle{
    font-size: 40px;
    font-weight:900;
    margin: 0;
  }
  
  @media (max-width: 847px) {
    .why-rent-tittle {
      font-size: 33px;
      font-weight:900;
    }
  }  

  @media (max-width: 825px) {
    .why-rent-tittle {
      font-size: 32.5px;
      font-weight:900;
    }
  }
  @media (max-width: 725px) {
    .why-rent-tittle {
      font-size: 28px;
      font-weight:900;
    }
  }
  
  @media (max-width: 625px) {
    .why-rent-tittle {
      font-size: 20.2px;
      font-weight:900;
    }
  }
  
  @media (max-width: 465px) {
    .why-rent-tittle {
      font-size: 18px;
      font-weight:900;
    }
  }
/* spanish version */
.por-que-rentar-tittle{
  font-size: 40px;
  font-weight:900;
  margin: 0;
}

@media (max-width: 1229px) {
  .por-que-rentar-tittle {
    font-size: 33px;
    font-weight:900;
  }
}  

@media (max-width: 1017px) {
  .por-que-rentar-tittle {
    font-size: 30px;
    font-weight:900;
  }
}
@media (max-width: 927px) {
  .por-que-rentar-tittle {
    font-size: 28px;
    font-weight:900;
  }
}

@media (max-width: 867px) {
  .por-que-rentar-tittle {
    font-size: 25.2px;
    font-weight:900;
  }
}

@media (max-width: 784px) {
  .por-que-rentar-tittle {
    font-size: 24px;
    font-weight:900;
  }
}

@media (max-width: 767px) {
  .por-que-rentar-tittle {
    font-size: 30px;
    font-weight:900;
  }
}
@media (max-width: 651px) {
  .por-que-rentar-tittle {
    font-size: 26px;
    font-weight:900;
  }
}
@media (max-width: 590px) {
  .por-que-rentar-tittle {
    font-size: 24px;
    font-weight:900;
  }
} 
@media (max-width: 530px) {
  .por-que-rentar-tittle {
    font-size: 21px;
    font-weight:900;
  }
} 
@media (max-width: 456px) {
  .por-que-rentar-tittle {
    font-size: 18px;
    font-weight:900;
  }
  .por-que-rentar-tittle-container{
    display: flex;
    flex-direction: column;
  }
} 
  /* ------------------------- WHY RENT ----------------------- */
  /* ---------------------------------------- old, need review  */
  
.why_rent{
  
  background-image:  url('../static/images/fonde.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
}

.toyota-why-rent{
  width: 100%;
  max-width: 100%;
  
  transform: scaleX(-1);
}

.why-rent-img{
  max-width: 25vw;

}
.why-rent-img:hover{
  transform: scale(1.2);
  transition: all .6s;
}
.hover-image{
  padding-right: 10px;
}

@media (max-width: 767px) {
  .toyota-why-rent{
    width: 100%;
    max-width: 100%;
    
    transform: scaleX(-1);
  }
  .why-rent-img-reasons{
    max-width: 60%;
    width: 60%;
    margin-bottom: 5vh;
    margin-top: 5vh; 
  }
  .why-rent-img-mobile{
  
    max-width: 35vw;
  }
}

/* ---------------------- WHY RENT END ----------------------- */


/* --------------- why rent reasons -------------------------- */



.textContainer {
  background-color: black;
  color: white;

}
.textContainer p{
  margin:0;
  font-weight: 600;
  font-size:18px ;
}

@media (max-width: 958px) {
  .textContainer p {
    font-weight: 600;
    font-size:16px ;
  }
}
@media (max-width: 888px) {
  .textContainer p {
    font-weight: 600;
    font-size:14px ;
  }
}

@media (max-width: 805px) {
  .textContainer p {
    font-weight: 600;
    font-size:13px ;
  }
}

@media (max-width: 761px) {
  .textContainer p {
    font-weight: 600;
    font-size:18px ;
  }
}
@media (max-width: 512px) {
  .textContainer p {
    font-weight: 600;
    font-size:16px ;
  }
}
 
@media (max-width: 466px) {
  .textContainer p {
    font-weight: 600;
    font-size:12.5px ;
  }
}

.imageContainer img {
  max-width: 100%;
  height: auto;
}

.col-reasons {
  flex: 1; /* additionally, equal width */
}

.why-rent-reasons-container:hover{
  transform: scale(1.2);
  transition: all .6s;
}

/* SPANISH VERSION */

.textContainerEs {
  background-color: black;
  color: white;

}
.textContainerEs p{
  margin:0;
  font-weight: 600;
  font-size:16.5px ;
}
@media (max-width: 1020px) {
  .textContainerEs p {
    font-weight: 600;
    font-size:15px ;
  }
}
@media (max-width: 958px) {
  .textContainerEs p {
    font-weight: 600;
    font-size:14px ;
  }
}
@media (max-width: 888px) {
  .textContainerEs p {
    font-weight: 600;
    font-size:13px ;
  }
}

@media (max-width: 805px) {
  .textContainerEs p {
    font-weight: 600;
    font-size:12px ;
  }
}

@media (max-width: 761px) {
  .textContainerEs p {
    font-weight: 600;
    font-size:15.1px ;
  }
}
@media (max-width: 512px) {
  .textContainerEs p {
    font-weight: 600;
    font-size:13.2px ;
  }
}
 
@media (max-width: 466px) {
  .textContainerEs p {
    font-weight: 600;
    font-size:11px ;
  }
}
